:root {
    --red: hsl(0, 78%, 62%);
    --cyan: hsl(180, 62%, 55%);
    --orange: hsl(34, 97%, 64%);
    --blue: hsl(212, 86%, 64%);
    --varyDarkBlue: hsl(234, 12%, 34%);
    --grayishBlue: hsl(229, 6%, 66%);
    --veryLightGray: hsl(0, 0%, 98%);
    --weight1: 200;
    --weight2: 400;
    --weight3: 600;
}

.main-cont{
    padding: 50px 10px;
}

/* body {
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    background-color: var(--veryLightGray);
} */

.attribution { 
    font-size: 11px; text-align: center; 
}
.attribution a { 
    color: hsl(228, 45%, 44%); 
}

h1:first-of-type {
    font-weight: var(--weight1);
    color: var(--varyDarkBlue);

}

h1:last-of-type {
    color: var(--varyDarkBlue);
}

@media (max-width: 400px) {
    h1 {
        font-size: 1.5rem;
    }
}

.header {
    text-align: center;
    line-height: 0.8;
    margin-bottom: 50px;
    margin-top: 100px;
}

.header p {
    margin: 0 auto;
    line-height: 2;
    color: var(--grayishBlue);
}


.box p {
    color: var(--grayishBlue);
}

.box {
    border-radius: 5px;
    box-shadow: 0px 30px 20px -20px var(--grayishBlue);
    padding: 30px;
    margin: 20px; 
    opacity: 1 ; 
}

.box:hover{
    opacity: 2;
    box-shadow: 0px 30px 40px -20px var(--grayishBlue);
}
.horizontal
{
    display: flex;
    justify-content: space-between;
}
.img-tool {
    margin-top: 1rem;
    height: 52px;
    max-width: none;
}


@media (max-width: 450px) {
    .box {
        height: 200px;
    }
}

@media (max-width: 950px) and (min-width: 450px) {
    .box {
        text-align: center;
        height: 250px;
    }
}

.cyan {
    border-top: 3px solid var(--blue);
}
.red {
    border-top: 3px solid #7cc576;
}
.blue {
    border-top: 3px solid var(--orange);
}
.orange {
    border-top: 3px solid var(--red);
}

h2 {
    color: var(--varyDarkBlue);
    font-weight: var(--weight3);
}


@media (min-width: 950px) {
    .row1-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .row2-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .box-down {
        position: relative;
        top: 160px;
    }
    .box {
        width: 30%;
        height: 260px;
     
    }
    .header p {
        width: 30%;
    }
    
}