 .header-nav
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  top: 0;
  position: sticky;
  box-shadow: 0 3px 24px 0 rgb(0 0 0 / 10%);
  padding: 0 2rem;
  background-color: #f8f8ff;
  border-bottom: 1px solid #eee;
  z-index: 9999;
  color: rgb(34, 34, 35);
}
#logo-div
{
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
}
#logo
{
  height: 60px;
  width: 60px;
}
#logo-div h3
{
  margin: 0.8rem;
}
nav a 
{
  margin: 0 2rem;
  color: #2d2d2d;
  font-weight: 600;
  text-decoration: none;
}

nav a:hover
{
  color: #3636ec;
}

.header-nav .nav-btn
{
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: rgb(38, 38, 38);
 display: none;
  opacity: 0;
  font-size: 1.8rem;
}

@media (max-width:800px) {
  .header-nav .nav-btn
  {
    display: inline;
    opacity: 1;
  }
  .header-nav nav {
    position: fixed;
    top: 0px;
    left: 0;
    height: 50%;
    width: 100%;
    display: flex;
    z-index: 9999;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    background-color: #f8f8ff;
    transition: 1.2s;
    margin-top: 5rem;
    transform: translateY(-140vh);
    border-bottom: 2px solid #b7b5b5;
    box-shadow: 0 3px 24px 0 rgb(0 0 0 / 10%);
}
  .header-nav {
    border-bottom: 1px solid #cfcfcf;
    padding: 0 1rem 0 0 ;
}
  .header-nav .responsive_nav
  {
    transform: none;
  }
  #logo-div h3
{
  font-size: 20px;
}

  .header-nav .responsive_nav a:focus .responsive_nav
  { 
    transform: translateY(-100vh);
    transform: none;
  }
  nav .nav-close-btn{
    /* position: absolute;
    top: 2rem;
    right: 2rem; */
    display: none !important;
  }
  nav a
  {
    font-size: 1rem;
  }
}

@media (max-width:1100px)
{
  nav a 
{
  margin: 0 1rem;
  font-weight: 400;
}

} 
@media (max-width:600px)
{
  #logo {
    height: 40px;
    width: 40px;
}
.logo-div h3
{
  font-size: 1.2rem;
}
}