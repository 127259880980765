#whyus {
  padding-bottom: 6rem;
}
.whatsetus-topic-bulletpoint {
  width: 8px;
  height: 8px;
  border: 1px solid #057aff;
  border-radius: 50%;
}
.whatsetus-topic-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 32px;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  grid-column-gap: 10px;
}
.whatsetus-topic-text {
  font-family: lato, sans-serif;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 2px;
}

.whatsetus-topic-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: start;
  -webkit-justify-content: space-evenly;
  -ms-flex-pack: start;
  justify-content: space-evenly;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
  grid-column-gap: 56px;
  grid-row-gap: 36px;
}
.whatsetus-topic-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 6px;
  margin-left: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  width: 33%;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-row-gap: 24px;
  border-left: 1px solid #057aff;
}
#first-div
{
  transition-delay: 0.4s;
}
#sec-div
{
  transition-delay: 0.6s;
}
#third-div
{
  transition-delay: 0.8s;
}
.whatsetus-topic-header {
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 100%;
  margin-left: -16px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-column-gap: 16px;
}
.whatsetus-topic-text {
  font-family: lato,sans-serif;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 2px;
}
.whatsetus-topic-icon {
  width: 32px;
  height: 32px;
  border: 1px solid #057aff;
  border-radius: 50%;
  background-color: #fff;
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.whatsetus-topic-blue-circle {
  width: 5px;
  height: 5px;
  margin-right: 32px;
  margin-left: -3px;
  border-radius: 50%;
  background-color: #057aff;
}
.whatsetus-topic-title {
  margin-top: -6px;
  font-family: lato,sans-serif;
  color: #057aff;
  font-size: 24px;
  line-height: 38px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.whyus-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}
#sub-heading {
  color: #050d15;
  margin-top: 2rem;
  font-size: 1.1rem;
  line-height: 30px;
}
@media (max-width: 800px) {
  #first-div {
    padding-top: 0;
  }
}

/* ------------------------------------------------------------------ */

@media (max-width: 560px) {
  .whatsetus-topic-container
  {
    flex-direction: column;
    padding-bottom: 64px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}
  .whatsetus-topic-item-wrapper {
    grid-row-gap: 18px;
  }
  .whatsetus-topic-title {
    font-size: 18px;
}
.whatsetus-topic-text {
  font-size: 16px;
}
#whyus {
  padding-bottom: 0rem;
}
#sub-heading {
  margin-top: 2rem;
  font-size: 1rem;
  line-height: 26px;
  text-align: justify;
}
.whatsetus-topic-wrapper {
  width: 98%;
}
}
