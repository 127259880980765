
/*        Column
#project
{
    background: #f8f8ff;
    width: 100%;
    padding-bottom: 4rem;
}
.project-title h1 {
    font-size: 3rem;
    font-family: 'Harabara';
    margin-bottom: 15px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 2px;
    line-height: 1;
    padding-bottom: 0;
    padding-top: 6rem;
    color: #057aff;
    padding-bottom: 2rem;
}
.project-container
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
}
.project-row
{
    width: 22%;
    height: 66vh;
    border-radius: 1rem;
    display: flex;
    background: #f5f9ff;
    justify-content: space-evenly;
    border: 1px solid #dce9f9;
    gap: 1rem;
    flex-direction: column-reverse;
    align-items: center;
}
#even-row
{
    flex-direction: column-reverse;
    
}
.project-row .project-text
{
    width: 92%;
    display: flex;
    flex-direction: column;
}
.project-row .project-img
{
    height: 200px;
    width: 94%;
    background: #4971c6;
    border-radius: 1rem;
    border: 2px inset #f0f5fb;
}
.project-text h2
{
    text-transform: uppercase;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 1px;
    color: #5151f0;
}
.project-text h1
{
    width: 84%;
    color: #2246c7;
    font-size: 2rem;
    font-weight: 400;
}
.project-text p
{
    color: #294ccb;
    text-align: justify;
}
@media(max-width:1100px)
{
    .project-text h1
{
    width: 98%;
}
}



@media(max-width:600px)
{
    .project-title h1 {
        font-size: 2rem;
        padding-top: 4rem;
    }
    .project-container
{
    display: flex;
    flex-direction: column;
}
    .project-row
{
    width: 90%;
    height: 78vh;
    gap: 1rem;
    flex-direction: column-reverse;
}
.project-row .project-text
{
    width: 90%;
    display: flex;
    flex-direction: column;
}
.project-row .project-img
{
    height: 240px;
    width: 90%;
    background: #144fcf;
    border-radius: 1rem;
    border: 2px inset #f0f5fb;
}
#even-row
{
    flex-direction: column-reverse;
}
}

*/
*{
    scroll-behavior: smooth;
}

#project
{
    background: #f8f8ff;
    width: 100%;
    padding-bottom: 5rem;
}
#project-text
{
    color: #050d15;
  margin-top: 0.2rem;
  padding-bottom: 2rem;
  font-size: 1.1rem;
  line-height: 10px;
  text-align: center;
}
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
  }
  .swiper-wrapper {
    transition-timing-function:linear;
 }
  
.project-title h1 {
    font-size: 3rem;
    font-family: 'Harabara';
    margin-bottom: 15px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 2px;
    line-height: 1;
    padding-bottom: 0;
    padding-top: 5rem;
    color: #057aff;
    padding-bottom: 2rem;
}
.project-container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
.project-row
{
    width: 82%;
    height: 500px;
    border-radius: 1rem;
    margin: 0 auto;
    display: flex;
    background: #f3f8fe;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #ccd1f8;
}
.project-row .project-text
{
    width: 40%;
    display: flex;
    flex-direction: column;
}
.project-row .project-img
{
    height: 300px;
    width: 34%;
    background: #4971c6;
    border-radius: 1rem;
    border: 2px inset #f0f5fb;
}
.project-text h2
{
    text-transform: uppercase;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 1px;
    color: #1780f9;
}
.project-text h1
{
    width: 70%;
    color: #144fcf;
    font-weight: 400;
}
.project-text p
{
    color: #1380fe;
    text-align: justify;
}

@media(max-width:600px)
{
    .project-title h1 {
        font-size: 2rem;
        padding-top: 4rem;
    }
    #project-text
{
    padding: 0 1.4rem 2rem 1.4rem;
  font-size: 1rem;
  line-height: 1.9rem;
  text-align: justify;
  margin: 0;
}
    .project-row
{
    width: 90%;
    height: auto;
    padding: 1rem 0;
    gap: 1rem;
    flex-direction: column-reverse;
}
.project-row .project-text
{
    width: 90%;
    display: flex;
    flex-direction: column;
}
.project-row .project-img
{
    height: 240px;
    width: 340px;
    background: #144fcf;
    border-radius: 1rem;
    border: 2px inset #f0f5fb;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
    display: none;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    display: none;
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    display: none;
}
.project-text h1 {
    width: 90%;
    padding: 0.6rem 0;
}
}