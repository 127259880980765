#hero 
{
  width: 100%;
  height: 90vh;
   background: #f8f8ff;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#hero .hero-container 
{
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 42%;
  margin-top: 0.1rem;
  flex-direction: column;
  padding: 0 15px;
}
#hero .hero-img
{
  width: 44%;
}
#hero .hero-img img
{
  width: 90%;
}
#hero .hero-logo {
  margin-bottom: 30px;
}
#hero h1 
{
  margin: 0 0 30px 0;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 2px;
  font-family: 'harabara';
  line-height: 56px;
  color: #057aff;
}
.software
{
  color: #057aff;
  font-weight: 700;
}
#hero h2 
{
  color: #606060;
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 56px;
  line-height: 4.6rem;
  text-transform: capitalize;
}
#hero .btn-get-started {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 14px 30px;
  border-radius: 3px;
  margin: 10px;
  width: 190px;
  color: #fff;
  background: #057aff;
}
#hero .btn-get-started:hover {
  transition: 0.5s;
  background: #0b6cdb;
  color: #f8f8ff;
}
@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
  #hero h2 {
    font-size: 42px;
    line-height: 4.2rem;
    text-transform: capitalize;
}
}
@media (max-width: 800px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    line-height: 46px;
  }
}

/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
section {
  padding: 0;
  overflow: hidden;
}

.section-bg {
  background-color: whitesmoke;
}

.section-title {
  text-align: center;
  padding-bottom: 40px;
}

.section-title h2 {
  font-size: 3rem;
  font-family: 'Harabara';
  
  /* text-transform: uppercase; */
  margin-bottom: 15px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1;
  padding-bottom: 0;
  color: #057aff;
}
.section-title p {
  margin-bottom: 0;
  color: #aeaeae;
}

@media (max-width:560px)
{
  #hero 
{
  flex-direction: column;
  height: auto;
  padding-top: 2rem;
  gap: 2rem;
}
#hero .hero-container
{
  width: 94%;
}
#hero .hero-img
{
  width:90%;
}
  .section-title h2 {
    font-size: 2rem;
  }
  #hero h2 {
   
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 400;
    line-height: 34px;
    text-transform: uppercase;
  }
  #hero h1 
{
  margin: 0 0 30px 0;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 2px;
  font-family: 'harabara';
  line-height: 24px;
  color: #057aff;
}

#hero h2 
{
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 3rem;
}
#hero .btn-get-started {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 10px;
  margin: 0;
  width: 146px;
}
}