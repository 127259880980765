.about .image {
  
  padding: 6px;
  border-radius: 0.4rem;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  background: #fefeff;
  border: 1px solid #fefefe;
}
#about
{
  background: #057aff;
  padding-bottom: 6rem;
}
.row
{
  margin-right: 0;
}
.about .content h3 {
  font-weight: 600;
  color: #f8f8ff;
  font-size: 30px;
}
.about .content ul {
  list-style: none;
  
  padding: 0;
}
.about p,h2
{
  color: #f8f8ff;
}
.about .section-title h2 {
  font-size: 3rem;
  font-family: 'Harabara';
  margin-bottom: 15px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1;
  padding-bottom: 0;
  color: #f8f8ff;
}
.about .content ul li {
  padding-bottom: 10px;
  display: flex;
  color: #f8f8ff;
  align-items: flex-start;
}
.about .content ul i {
  font-size: 28px;
  padding: 2px 6px 0 0;
  color: #fbfdfe;
}
.about .content p:last-child {
  margin-bottom: 0;
  color: #f8f8ff;
}
.about .section-title p
{
  color: #f8f8ff;
}

@media(max-width:600px)
{
  .about .content h3 {
    font-weight: 600;
    color: #f8f8ff;
    font-size: 18px;
}
}