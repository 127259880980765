.featured {
  padding: 40px 0 0 0;
  background: white;
}
.featured .nav-tabs {
  border: 0;
}
.featured .nav-link {
  border: 0;
  padding: 20px;
  color: #151515;
  transition: 0.3s;
}
.featured .nav-link h4 {
  font-size: 18px;
  font-weight: 600;
  transition: 0.3s;
}
.featured .nav-link:hover h4 {
  color: #7cc576;
}
.featured .nav-link p {
  font-size: 14px;
  margin-bottom: 0;
}
.featured .nav-link.active {
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.08);
}
.featured .nav-link.active h4 {
  color: #7cc576;
}
.featured .tab-pane.active {
  -webkit-animation: slide-down 0.5s ease-out;
  animation: slide-down 0.5s ease-out;
}
@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide-down {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
