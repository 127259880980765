.contact .info {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
  }
  #contact
  {
    /* background: #07101a; */
    background: #0d1117;
    
  }
  .contact .section-title h2
  {
    color: #057aff;
    font-weight: 100;
    letter-spacing: 3px;
    font-size: 26px;
    margin-bottom: 76px;
  }
  .small-heading-white
  {
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    color: #fff;
    font-size: 21px;
    line-height: 34px;
    font-weight: 400;
    letter-spacing: 3px;
  }
  .heading-explain
  {
  margin-left: 22%;
    margin-top: 20px;
    margin-bottom: 0;
    color: #fff;
    width: 56%;
    line-height: 2rem;
    font-size: 17px;
    letter-spacing: 2px;
    padding-right: 20px;
    padding-left: 20px;
    font-weight: 300;
  }
  .contact .info a{
    padding:  0 0 0 18px;
    color: #e1eef6;
    font-weight: 500;
  }
  .heading-explain strong
  {
    font-weight: 500;
  }
  .contact .info i {
    font-size: 20px;
    color: #057aff;
    float: left;
    width: 44px;
    height: 44px;
    background: #19232e;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  #contact .row
  {
    margin-top: 60px;
  }
  .contact .info
  {
    background: #161b22;
    /* border: 1px dashed #057aff; */
  }
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #d7ebf7;
  }
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #abbdc8;
  }
  .contact .info .email, .contact .info .phone {
    margin-top: 40px;
  }
  .contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
    background: #057aff;
    color: #fff;
  }
  .contact .php-email-form {
    width: 100%;
    background: #161b22;
    /* border: 1px dashed #057aff; */
    padding: 20px;
    border-radius: 5px;
  }
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  .contact .php-email-form .error-message br + br {
    margin-top: 25px;
  }
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #057aff;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  .contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
  }
  .contact .php-email-form input {
    height: 44px;
    background: #161b22;
    border: none;
    color: #abbdc8;
    border-bottom: 1px solid #057aff;
  }
  .contact .php-email-form textarea {
    padding: 10px 12px;
    background: #161b22;
    color: #abbdc8;
    border: none;
    border-bottom: 1px solid #057aff;
  }
  .contact .button[type=submit] {
    background: #057aff;
    border: 0;
    cursor: pointer;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
  }
  .contact .button[type=submit]:hover {
    background: #0176fc;
  }
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width:600px)
  {
    .contact .section-title h2 {
    color: #057aff;
   line-height: 2rem;
    font-size: 20px;
  }
  .heading-explain {
    margin-left: 5%;
    margin-top: 20px;
    margin-bottom: 0;
    color: #fff;
    width: 90%;
    font-size: 16px;
    line-height: 1.8rem;
}
  }