#faq
{
  padding-top: 5rem;
  padding-bottom: 1rem;
}
.h4,
 h4 {
  color: #4a485a;
    font-size: 20px;
    font-weight: 400;
    
  } 
  .text-center h1
  {
    font-size: 3rem;
  font-family: 'Harabara';
  /* text-transform: uppercase; */
  margin-bottom: 30px;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 1;
  padding-bottom: 0;
  color: #057aff;
  }

  h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 15px;
    padding-bottom: 0;
    color: #4a485a;
  }
  
  .asection{
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: bolder;
    color: #555555;

  }
.asection:hover,
.asection:visited{
    color: #057aff;
    
}
  .fa,
  .fas {
      
    font-weight: 400;
    font-size: 1.2rem;
    font-style:normal;
  }
  
  .right-0 {
    right: 0;
  }
  
  .top-0 {
    top: 0;
  }
  
  .h-100 {
    height: 100%;
  }
  
 /*  a.text-secondary:focus,
  a.text-secondary:hover {
    text-decoration: none;
    color: white;
  } */
 
  #accordion .fa-plus {
    transition: -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    
  }
  
  #accordion a[aria-expanded=true] .fa-plus {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  @media (max-width:560px)
  {
    .text-center h1
  {
    font-size: 1.6rem;
 
 
  }
  }