.client-div{
    visibility: visible;
    animation-name: fadeInUp;
}
.section-title-client h2
{
	color: #fff;
	font-size: 16px;
	line-height: 34px;
	margin-bottom: 4rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: 3px;
	text-transform: uppercase;
}



#clients {
    padding: 10px 0;
	background: #0d1117;
}
/* 
body {
	align-items: center;
	background: #E3E3E3;
	display: flex;
	height: 100vh;
	justify-content: center;
	} */
	@-webkit-keyframes scroll {
	0% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	}
	100% {
	-webkit-transform: translateX(calc(-250px * 7));
	transform: translateX(calc(-250px * 7));
	}
	}
	@keyframes scroll {
	0% {
	-webkit-transform: translateX(0);
	transform: translateX(0);
	}
	100% {
	-webkit-transform: translateX(calc(-250px * 7));
	transform: translateX(calc(-250px * 7));
	}
	}
	.slider {
		background: #0d1117;
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
	height: 100px;
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 100%;
	}
	.slider::before, .slider::after {
	content: "";
	height: 100px;
	position: absolute;
	width: 200px;
	z-index: 2;
	}
	.slider::after {
	right: 0;
	top: 0;
	-webkit-transform: rotateZ(180deg);
	transform: rotateZ(180deg);
	}
	.slider::before {
	left: 0;
	top: 0;
	}
	.slider .slide-track {
	-webkit-animation: scroll 40s linear infinite;
	animation: scroll 40s linear infinite;
	display: flex;
	width: calc(250px * 14);
	}
	.slider .slide {
	height: 100px;
	width: 250px;
	}
	.slide img
	{
		height: 80px;
		width: 180px;
		
	}