#service {
  background: #06111c;
  padding: 6rem 0;
  min-height: 100vh;
  width: 100%;
}
.our-services p {
  color: aliceblue;
  text-align: justify;
}
.our-services h1 {
  font-size: 3rem;
    font-family: 'Harabara';
    text-align: center;
    margin-bottom: 15px;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 1;
    color: #057aff;
}
.our-services h2 {
  text-align: center;
  color: #fff;
  font-weight: 300;
  width: 50%;
  margin-left: 26%;
  padding-bottom: 2rem;
    margin-top: 2rem;
    font-size: 1.1rem;
    line-height: 30px;

}
.our-services h3 {
  color: #057aff;
  font-size: 21px;
}
.services-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 5rem;
  justify-content: center;
  gap: 2rem;
}
.our-service-cards {
  width: 26%;
  transition: 0.8s;
  border: 2px solid #fff;;
  text-align: center;
  align-items: center;
  transition: 0.4s;
  padding: 1rem;
}
.our-service-cards:hover
{
  border: 2px double #057aff;
}

.our-service-cards img {
  transform: translateY(-80px);
  height: 140px;
}
.our-service-cards h3 {
  text-align: center;
  transform: translateY(-40px);
}
.our-service-cards p {
  text-align: center;
}


/* ----------------------------------------------------------*/
/* ---------------------- Laptop ---------------------------*/
@media (max-width: 1100px) {
  .our-services h2 {
    width: 50%;
    margin-left: 26%;
    margin-bottom: 2rem;
    line-height: 2rem;
    letter-spacing: 1px;
    font-size: 20px;
  }
  .our-service-cards img {
    margin-left: 0;
  }
}

@media (max-width: 800px) {
  .our-services h2 {
    width: 70%;
    margin-left: 16%;
    margin-bottom: 2rem;
    font-size: 18px;
  }
  .our-service-cards img {
    width: 80%;
    transform: translateY(-60px);
  }
  .our-services p {
    font-size: 16px;
  }
  .services-container {
    gap: 1rem;
  }
  .our-service-cards {
    width: 31%;
  }
}


@media (max-width: 580px) {
    .our-services h2 {
      width: 90%;
      margin-left: 5%;
      text-align: justify;
      margin-bottom: 2rem;
      font-size: 18px;
    }
    
    .services-container {
      gap: 5rem;
      flex-direction: column;
      padding-left: 1rem;
    }
    .our-service-cards {
      width: 96%;
    }
    .our-service-cards p {
      text-align: justify;
  }
    .our-service-cards img {
      width: 50%;
      transform: translateY(-80px);
    }
  }

