#footer {
  background: #0d1117;;
    color: #fff;
    font-size: 14px;
    position: relative;
  }
  #footer::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
   
    z-index: 1;
  }
  #footer .footer-top {
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 30px 0 16px 0;
  }
  #footer .footer-top .footer-logo img {
    height: 80px;
  }
  #footer .footer-top h3 {
    font-size: 36px;
    font-weight: 700;
    color: #fff;
    position: relative;
    font-family: "Poppins", sans-serif;
    padding: 30px 0 0 0;
    margin-bottom: 0;
  }
  #footer .footer-top p {
    font-size: 15;
    font-style: italic;
    margin: 30px 0 0 0;
    padding: 0;
  }
  #footer .footer-top .footer-newsletter {
    text-align: center;
    font-size: 15px;
    margin-top: 30px;
  }
  #footer .footer-top .footer-newsletter form {
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  #footer .footer-top .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  #footer .footer-top .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    cursor: pointer;
    padding: 0 20px;
    background: #057aff;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 50px 50px 0;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  #footer .footer-top .footer-newsletter form input:focus
  {
    border: none;
    outline: none;
  }
  #footer .footer-top .footer-newsletter form input[type=submit]:hover {
    background: #057aff;
  }
  #footer .footer-top .social-links {
    margin-top: 30px;
    height: 30px;
    display: inline-block !important;
  }
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #057aff;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  #footer .footer-top .social-links a:hover {
    background: #0075fa;
    color: #fff;
    text-decoration: none;
  }
  #footer .footer-bottom {
    border-top: 1px solid #222222;
    z-index: 2;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
  }
 
  #footer .credits {
    text-align: center;
    font-size: 13px;
    padding-top: 5px;
  }

 #footer .copyright
  {
    color: #abbdc8;
    font-size: 16px;
    margin-top: 2rem;
    text-align: left;
    margin-left: 1rem;
  }

  @media(max-width:600px){
    #footer .copyright
    {
      font-size: 12px;
    }
  
  }