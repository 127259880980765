.tech-stacks
{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4rem;
  margin-bottom: 2.6rem;
}
#tech-text
{
  color: #050d15;
  margin-top: 2rem;
  font-size: 1.1rem;
  line-height: 30px;
}
.hidden
{
  opacity: 0;
  filter: blur(2px);
  transform: translateX(-100%);
  transition: all 2s;
}

.show
{
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

#toolimage{
  background: #ffffff;
  border: 1px solid #eaeaea;
  width: 120px;
  cursor: pointer;
  border-radius: 0.6rem;
  -webkit-box-shadow: 2px 10px 19px 0px rgba(230,230,230,1);
-moz-box-shadow: 2px 10px 19px 0px rgba(230,230,230,1);
box-shadow: 2px 10px 19px 0px rgba(230,230,230,1);
}

#toolimage:hover
{
  transform: translateY(-10px);
  -webkit-box-shadow: 2px 10px 19px 0px rgb(203, 203, 203);
  transition: 0.5s;
  delay:0.6s;
-moz-box-shadow: 2px 10px 19px 0px rgb(203, 203, 203);
box-shadow: 2px 10px 19px 0px rgb(201, 201, 201);
}

#toolimage:nth-child(2)
{
  transition-delay: 200ms;
}
#toolimage:nth-child(3){
  transition-delay: 450ms;
}
#toolimage:nth-child(4){
  transition-delay: 750ms;
}
#toolimage:nth-child(5){
  transition-delay: 900ms;
}

.tech-class {
  width: 100%;
  /* min-height: 100vh; */
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  padding-bottom: 6rem;
}
.section-title {
  width: 100%;
  margin-top: 5rem;
}
.tech-stacks h3 {
  display: block;
}
.img-tool {
  margin-top: 1rem;
  height: 60px;
  width: none;
}

.tech-block-horizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 90%;
  gap: 50px;
}
.tech-stacks h3 {
  margin-top: 16px;
  color: #0d1d33;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
}
.tech-stacks h2 {
  margin-top: 10px;
  margin-bottom: 0;
  font-family: Lato, sans-serif;
  color: #057aff;
  font-size: 18px;
  line-height: 34px;
  width: 13%;
  font-weight: 400;
  text-align: start;
  letter-spacing: 3.83px;
  text-transform: uppercase;
}
.tech-stack-class-container {
  min-width: 50%;
  border-radius: 1rem;
  /* border-left: 4px solid #bfd9f7;
  border-top: 1px solid #bfd9f7;
  border-bottom: 1px solid #bfd9f7;
  border-right: 4px solid #bfd9f7; */
}
#specialimage {
  opacity: 0.8;
}
/* ------------------------------------------------------- */
/* ------------------------------------------------------- */

@media (max-width: 560px) {
  .img-tool {
    margin-top: 1rem;
    height: 36px;
    width: auto;
  }
  #tech-text
{
  text-align: justify;
  padding: 0 1rem;
  margin-top: 2rem;
  font-size: 1rem;
}
  .tech-stack-class-container {
    width: 96%;
  }
  .tech-stacks h3 {
    font-size: 12px;
}
.tech-block-horizontal {
  gap: 30px;
  margin: 00;
  justify-content: center;
}
.tech-class {
  padding-bottom: 1rem;
}
.tech-stacks
{
  flex-direction: column;
  gap: 1.4rem;
}
}
